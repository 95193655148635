import * as React from 'react'
import PropTypes from 'prop-types'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const Card = ({ variant, className, children }) => {
    const classes = [
        'c-card',
        ...(variant ? [`c-card--${variant}`] : []),
        ...(className ? [className] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    return <div className={concatenatedClasses}>{children}</div>
}

Card.propTypes = {
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['alt', 'feature']),
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

Card.defaultProps = {
    children: '<p>Card content goes here</p>'
}

export default Card
